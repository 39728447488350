import styled from "styled-components"

const Button = styled.a`
  background-color: orange;
  border-radius: 4px;
  font-weight: 500;
  padding: 0.75rem 2rem;
  text-decoration: none;
  margin-top: 0.3em;
  margin-bottom: 0.2em;
  font-size: 1.2em;
  align-items: center;
  display: flex;
  color: white;
  white-space: nowrap;
  transition: all 200ms ease-in;
  position: relative;

  :after {
    position: absolute;
    bottom: 0.5rem;
    left: 2rem;
    right: -2rem;
    width: 0%;
    content: ".";
    color: transparent;
    background: lightgray;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color: lightgray;
    ::after {
      width: 3.7em;
    }
  }

  @media (max-width: 768px) {
    font-size: 1em;
    position: sticky;
    display: flex;
  }

  @media (max-width: 359px) {
    margin-right: 5px;
  }
`

export default Button
