import './base.css'
import Facebook from './images/facebook.svg'
import Instagram from './images/Instagram.svg'
import React from 'react'
import styles from './footer.module.css'

class Footer extends React.Component {
  render() {
    return (
        <footer className={styles.footer}>
            <div className={styles.footerGrid}>
                <div className={styles.footerItem}>
                    <div className={styles.connectText}>FOLLOW US!</div>
                    </div>
                <div className={styles.socialLinks}>
                    <div><a href="https://www.facebook.com/JacksonWalshMemorialFund"><img alt="www.facebook.com/JacksonWalshMemorialFund" border="0" src={Facebook} /></a></div>
                    <div><a href="https://www.instagram.com/jacksonwalshmemorialfund/"><img alt="www.instagram.com/jacksonwalshmemorialfund" border="0" src={Instagram} /></a></div>
                </div>
                <div className={styles.footerItem}>
                    <div className={styles.copyright}>Copyright © 2021 The Jackson Walsh Memorial Fund. All rights reserved.</div>
                </div>
            </div>
            
    <div className={styles.container}> 
                <svg viewBox="0 0 120 28" className={styles.wave} preserveAspectRatio="none">
                {/* <defs> 
                    <filter id="goo">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="blur" />
                    <feColorMatrix in="blur" mode="matrix" values="
                        1 0 0 0 0  
                        0 1 0 0 0  
                        0 0 1 0 0  
                        0 0 0 13 -9" result="goo" />
                    <xfeBlend in="SourceGraphic" in2="goo" />
                    </filter> */}
                    <path id="wave" d="M 0,10 C 30,10 30,15 60,15 90,15 90,10 120,10 150,10 150,15 180,15 210,15 210,10 240,10 v 28 h -240 z" />
                {/* </defs>  */}

                {/* <use id="wave3" className={styles.wave3} xlinkHref="#wave" x="0" y="-2" ></use> 
                <use id="wave2" className={styles.wave2} xlinkHref="#wave" x="0" y="0" ></use>
                <g className={styles.gooeff} filter="url(#goo)">
                    <use id="wave1" className={styles.wave} xlinkHref="#wave" x="0" y="2" />
                </g> */}
                </svg>
            </div>
            
            <div className={styles.container}> 
                        <svg viewBox="0 0 120 28" className={styles.wave2} preserveAspectRatio="none">
                        {/* <defs> 
                            <filter id="goo">
                            <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="blur" />
                            <feColorMatrix in="blur" mode="matrix" values="
                                1 0 0 0 0  
                                0 1 0 0 0  
                                0 0 1 0 0  
                                0 0 0 13 -9" result="goo" />
                            <xfeBlend in="SourceGraphic" in2="goo" />
                            </filter> */}
                            <path id="wave" d="M 0,10 C 30,10 30,15 60,15 90,15 90,10 120,10 150,10 150,15 180,15 210,15 210,10 240,10 v 28 h -240 z" />
                        {/* </defs>  */}
        
                        {/* <use id="wave3" className={styles.wave3} xlinkHref="#wave" x="0" y="-2" ></use> 
                        <use id="wave2" className={styles.wave2} xlinkHref="#wave" x="0" y="0" ></use>
                        <g className={styles.gooeff} filter="url(#goo)">
                            <use id="wave1" className={styles.wave} xlinkHref="#wave" x="0" y="2" />
                        </g> */}
                        </svg>
                    </div>
            
            <div className={styles.container}> 
                        <svg viewBox="0 0 120 28" className={styles.wave3} preserveAspectRatio="none">
                        {/* <defs> 
                            <filter id="goo">
                            <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="blur" />
                            <feColorMatrix in="blur" mode="matrix" values="
                                1 0 0 0 0  
                                0 1 0 0 0  
                                0 0 1 0 0  
                                0 0 0 13 -9" result="goo" />
                            <xfeBlend in="SourceGraphic" in2="goo" />
                            </filter> */}
                            <path id="wave" d="M 0,10 C 30,10 30,15 60,15 90,15 90,10 120,10 150,10 150,15 180,15 210,15 210,10 240,10 v 28 h -240 z" />
                        {/* </defs>  */}
        
                        {/* <use id="wave3" className={styles.wave3} xlinkHref="#wave" x="0" y="-2" ></use> 
                        <use id="wave2" className={styles.wave2} xlinkHref="#wave" x="0" y="0" ></use>
                        <g className={styles.gooeff} filter="url(#goo)">
                            <use id="wave1" className={styles.wave} xlinkHref="#wave" x="0" y="2" />
                        </g> */}
                        </svg>
                    </div>
        </footer>
    )
  }
}

export default Footer