// NavbarLinks.js

import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"

const NavItem = styled(Link)`
  text-decoration: none;
  color: white;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: lightgray;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color: lightgray;
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 8;
    color: black;

    :hover {
      color: goldenrod;
      ::after {
        width: 100%;
      }
    }
  }
`

const NavbarLinks = ({ newsCount }) => {
  if (newsCount.allContentfulInTheNews.totalCount > 2) {
    return (
      <>
        <NavItem to="/">Home</NavItem>
        <NavItem to="/news">Impact</NavItem>
        <NavItem to="/contact">Contact Us</NavItem>
      </>
    )
  }
  else {
    return (
      <>
        <NavItem to="/">Home</NavItem>
        <NavItem to="/contact">Contact Us</NavItem>
      </>
    )
  }
}
export default function MyNavbarLinks(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allContentfulInTheNews {
            totalCount
          }
        }
      `}
      render={data => <NavbarLinks newsCount={data} {...props} />}
    />
  )
}
NavbarLinks.propTypes = {
  data: PropTypes.shape({
    allContentfulInTheNews: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
}
